<template>
  <div class="offer-card">
    <div class="offer-card__header">
      <AvatarCustomer :size="50" :src="item?.seller?.avatar?.preview" />
      <div class="offer-card__info">
        <div class="offer-card__info-top">
          <div class="offer-card__info-title">
            {{ item?.seller?.company?.name }}
          </div>
          <div class="offer-card__info-date">
            {{ dayjs(item?.created_at).format("MM/DD") }}
          </div>
        </div>
        <div class="offer-card__info-bottom">
          <div class="offer-card__info-rating">
            <v-rating
              v-model="rating"
              density="default"
              :active-color="'#FB8C00'"
              :length="1"
            ></v-rating>

            5.0
          </div>

          <!-- <div class="offer-card__info-city">Львів</div> -->
        </div>
      </div>
    </div>
    <div class="offer-card__details">
      <div class="offer-card__price">
        Ціна: <span>{{ formatPrice(item?.price) }}</span> грн
      </div>
      <nuxt-link
        class="offer-card__link"
        :to="`/my-requests/${item?.order?.id}/request-offer/${item?.id}`"
        >Переглянути <v-icon class="icon">mdi-chevron-right</v-icon></nuxt-link
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDayjs } from "#dayjs";
import AvatarCustomer from "~/components/AvatarCustomer.vue";

const props = defineProps({
  item: Object,
});

const rating = ref(1);

const dayjs = useDayjs();

const formatPrice = (price: null | number) => {
  if (price === null) price = 0;

  return price.toLocaleString("uk-UA", { minimumFractionDigits: 0 });
};
</script>

<style scoped lang="scss">
.offer-card {
  margin: 5px;
  border-radius: 8px;
  background: var(--color-main-white);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__header {
    display: flex;
    gap: 24px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
  }

  &__info-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info-title {
    color: var(--color-primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  &__info-date {
    color: var(--color-primary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__info-rating {
    display: flex;
    align-items: center;
    font-size: 14px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__info-city {
    font-size: 14px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__info-bottom {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-left: -14px;
    margin-top: -14px;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price {
    color: var(--color-primary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-primary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}
</style>
