<template>
  <div class="documents">
    <div
      class="documents__img-wrapper"
      v-for="doc in adminStore.currentSellerInfo.documents"
      :key="doc.id"
    >
      <a :href="doc.url" target="_blank">
        <img v-lazy-load  :data-src="doc.url" class="documents__img" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
const adminStore = useAdminStore();
</script>

<style scoped lang="scss">
.documents {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  &__img {
    max-width: 150px;
    width: 100%;
    border-radius: 4px;
  }

  &__img-wrapper {
    display: flex;
    border-radius: 8px;
    padding: 6px;
    border: 1px solid var(--color-border-default);
    cursor: pointer;
  }
}
</style>
