import { defineStore } from "pinia";
import { useUserStore } from "@/stores/useUserStore";
import {
  type NotificationsDataInterface,
  type NotificationInterface,
  type NotificationDataInterface,
} from "@/models/notifications";

export const useNotificationsStore = defineStore("notification-store", () => {
  const echo = useEcho();
  const client = useSanctumClient();
  const userStore = useUserStore();
  const userId = ref(userStore.user?.id);
  const notificationsList = ref<NotificationInterface[]>([]);
  const notificationsCurrentPage = ref<number | null>(null);
  const notificationsTo = ref<number | null>(null);
  const notificationsFrom = ref<number | null>(null);
  const notReadedNotifications = computed(() => {
    return notificationsList.value.filter((n) => !n.read_at).length;
  });

  const stopAllListeners = () => {
    echo.leaveAllChannels();
  };

  const subscribeToNotificationsChannel = () => {
    const user = userStore.user?.id;
    const name = `notifications.common.${user}`;

    echo
      .private(name)
      .notification((e: NotificationDataInterface) => {
        addNotification(e);
      })
      .error((e: object) => {
        console.error("Private channel error", e);
      });
  };

  const addNotification = (message: NotificationDataInterface) => {
    try {
      const notification: NotificationInterface = {
        id: message.id || Math.random().toString(36).substring(7),
        data: message,
        read_at: null,
        created_at: new Date().toISOString(),
      };

      notificationsList.value.unshift(notification);
    } catch (e) {}
  };

  const getAllNotifications = async () => {
    const response: NotificationsDataInterface = await client(
      `api/users/${userId.value}/notifications`
    );
    notificationsList.value = response.data;
    notificationsCurrentPage.value = response.current_page;
    notificationsTo.value = response.to;
    notificationsFrom.value = response.from;
  };

  const readAllNotifications = async () => {
    await client(`api/users/${userId.value}/notifications/read`, {
      method: "PUT",
    });
  };

  return {
    getAllNotifications,
    notificationsList,
    notificationsCurrentPage,
    notificationsTo,
    notificationsFrom,
    readAllNotifications,
    subscribeToNotificationsChannel,
    stopAllListeners,
    notReadedNotifications,
  };
});
