import { defineStore } from "pinia";
import { useUserStore } from "@/stores/useUserStore";
import {
  type SellerRquestsResponseInterface,
  type SellerRequestItemInterface,
} from "@/models/sellerRequests";

export const useSellerRequestStore = defineStore("seller-request-store", () => {
  const sanctumClient = useSanctumClient();
  const runtimeConfig = useRuntimeConfig();
  const BASE_API_URL: string = runtimeConfig.public.apiBaseUrl as string;
  const userStore = useUserStore();
  const getSellerId = ref(userStore?.user?.id);
  const requestsList = ref<{
    data: SellerRequestItemInterface[];
    links: { next: string; prev: string };
  }>({
    data: [],
    links: {
      next: "",
      prev: "",
    },
  });
  const requestItem = ref<SellerRequestItemInterface>({});
  const loader = ref(true);

  const getSellerRequestsList = async (queries = { page: 1 }) => {
    try {
      const response: SellerRquestsResponseInterface = await sanctumClient(
        `/api/orders`,
        {
          params: {
            ...queries,
          },
        }
      );
      requestsList.value = {
        data: response.data,
        links: {
          next: response.links.next,
          prev: response.links.prev,
        },
      };
    } catch (e) {
      console.error(e);
    }
  };

  const getSellerRequestsListByFilter = async (id: number) => {
    try {
      const validId = id >= 0 ? `/${id}` : "";
      if (validId) {
        const response: SellerRquestsResponseInterface = await sanctumClient(
          `api/sellers/${getSellerId.value}/orders${validId}`
        );
        requestsList.value = response.data;
      } else {
        getSellerRequestsList();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getSellerRequestItem = async (id: number) => {
    try {
      loader.value = true;
      const { data: response }: { data: SellerRequestItemInterface } =
        await sanctumClient(`${BASE_API_URL}/api/orders/${id}`);
      requestItem.value = response;
    } catch (e) {
      console.error(e);
    } finally {
      loader.value = false;
    }
  };

  return {
    getSellerRequestsList,
    getSellerRequestsListByFilter,
    requestsList,
    requestItem,
    getSellerRequestItem,
    loader,
  };
});
