<template>
  <NuxtLayout>
    <v-app>
      <div class="page-error">
        <span class="page-error__code">{{ error?.statusCode }}</span>

        <span class="page-error__desc">{{ error?.statusMessage }}</span>

        <IconPlanet class="page-error__img" />

        <div class="page-error__button">
          <v-btn
            size="large"
            color="black"
            block
            variant="flat"
            @click="navigateTo('/')"
          >
            На головну
          </v-btn>
        </div>
      </div>
    </v-app>
  </NuxtLayout>
</template>

<script setup lang="ts">
import IconPlanet from "@/components/Icons/IconPlanet.vue";
import { useError } from "#app";

const error = useError();
</script>

<style scoped lang="scss">
.page-error {
  padding: 80px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: calc(100vh - var(--header-default-height) - 55px);

  &__code {
    margin-bottom: 24px;
    color: #000;
    font-size: 100px;
    font-weight: 600;
  }

  &__desc {
    color: var(--color-primary-black);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 40px;
  }

  &__img {
    margin-bottom: 60px;
  }

  &__button {
    width: 100%;
    margin-top: 60px;

    @media (max-width: 600px) {
      margin-top: auto;
    }
  }
}
</style>
