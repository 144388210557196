import validate from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  filter: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/middleware/filter.ts"),
  "is-seller": () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/middleware/isSeller.ts"),
  role: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/middleware/role.ts"),
  "sanctum:auth": () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}