<template>
  <div class="admin-header--fixed">
    <div class="admin-header">
      <div class="admin-header__info">
        <IconAdminLogo />
        <div class="admin-header__info-wrapper">
          <span class="admin-header__info-title">CarPot</span>
          <span class="admin-header__info-subtitle"
            >Адміністративна панель</span
          >
        </div>
      </div>
      <div class="admin-header__actions">
        <button @click="navigateTo('/notifications')">
          <v-badge :content="1"><v-icon>mdi-bell-outline</v-icon></v-badge>
        </button>

        <button class="admin-header__actions-account">
          <div class="admin-account">
            <AvatarCustomer :size="32" />
            <div class="admin-account__info">
              <span class="admin-account__info-name">
                {{ userStore.user?.name }}
              </span>
              <span class="admin-account__info-role">Адмін тест</span>
            </div>
          </div>
          <v-icon class="header__action-chevron">mdi-chevron-down</v-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/useUserStore";
import IconAdminLogo from "~/components/Icons/IconAdminLogo.vue";
import AvatarCustomer from "~/components/AvatarCustomer.vue";

const userStore = useUserStore();
</script>

<style scoped lang="scss">
.admin {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11.5px 16px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    background: var(--color-main-white);
    position: relative;
    z-index: 99999;

    &--fixed {
      top: 0;
      left: 0;
      position: fixed;
      width: 100%;
      z-index: 3;
    }
  }

  &-header__info {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  &-header__info-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-header__info-title {
    color: var(--color-main-dark);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.25px;
  }

  &-header__info-subtitle {
    color: var(--color-secondary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &-header__actions {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  &-header__actions-account {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.admin-account {
  display: flex;
  align-items: center;
  gap: 14px;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__info-name {
    color: var(--color-main-dark);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  &__info-role {
    color: var(--color-main-dark);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
</style>
