export interface SellerRquestsResponseInterface {
  data: SellerRequestItemInterface[];
  links: SellerRequestLinksInterface;
  meta: SellerRequestMetaInterface;
}

export interface SellerRequestItemInterface {
  id: number;
  car_brand: {
    id: number;
    slug: string;
    name: string;
    created_at: string;
    updated_at: string;
  };
  car_model: {
    id: number;
    name: string;
    slug: string;
    car_brand_id: number;
    year_from: number;
    year_to: number;
    created_at: string;
    updated_at: string;
  };
  vin: number | null;
  part_category: {
    id: number;
    name: string;
    parent: {
      id: number;
      name: string;
      slug: string;
      created_at: string;
      updated_at: string;
      _lft: number;
      _rgt: number;
      parent_id: number | null;
    };
    created_at: string;
    updated_at: string;
  };
  customer: {
    id: number;
    name: string;
    email: string;
    phones: string[];
    avatar: {
      url: string;
      preview: string;
    };
  };
  status: SellerRequestStatus;
  part_code: string;
  part_description: string;
  comment: null;
  recommended_price: number;
  part_type: {
    value: number;
    label: string;
  };
  part_condition: {
    value: number;
    label: string;
  };
  created_at: string;
  updated_at: string;
  year: string;
}

export enum SellerRequestStatus {
  ACTIVE = 0,
  CONFIRMED = 1,
  CANCELED = 2,
  EXPIRED = 3,
}

export interface SellerRequestLinksInterface {
  first: string;
  last: string;
  prev: string;
  next: string;
}

export interface SellerRequestMetaInterface {
  current_page: number;
  from: number;
  last_page: number;
  links: [
    {
      url: string;
      label: string;
      active: boolean;
    },
    {
      url: string;
      label: string;
      active: boolean;
    },
    {
      url: string;
      label: string;
      active: boolean;
    }
  ];
  path: string;
  per_page: number;
  to: number;
  total: number;
}
