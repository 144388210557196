<template>
  <div class="sliced-text">
    {{ descriptions.slice(0, descriptionSliceIndex)
    }}{{
      descriptions.length > props.sliceIndex &&
      descriptionSliceIndex === props.sliceIndex
        ? "..."
        : ""
    }}
    <span
      v-if="
        descriptions.length > props.sliceIndex &&
        descriptionSliceIndex === props.sliceIndex
      "
      @click="descriptionSliceIndex = descriptions.length"
    >
      Читати більше
    </span>
    <span
      v-if="
        descriptions.length > props.sliceIndex &&
        descriptionSliceIndex === descriptions.length
      "
      @click="descriptionSliceIndex = props.sliceIndex"
    >
      Читати меньше
    </span>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  sliceIndex: {
    type: Number,
    default: 150,
  },
});

const descriptions = ref(props.text || "");
const descriptionSliceIndex = ref(
  descriptions.value.length > props.sliceIndex
    ? props.sliceIndex
    : descriptions.value.length
);
</script>

<style lang="scss" scoped>
.sliced-text {
  color: var(--color-primary-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.048px;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  span {
    cursor: pointer;
    color: var(--color-main-info);
  }
}
</style>
