import { defineStore } from "pinia";
import { useSellerStore } from "@/stores/useSellerStore";
import { useUserStore } from "@/stores/useUserStore";

enum UserModeEnum {
  SELLER = "seller",
  DEFAULT = "default",
}
export const useUserModeStore = defineStore("user-mode-store", () => {
  const sellerStore = useSellerStore();
  const userStore = useUserStore();
  const userMode = ref(UserModeEnum.DEFAULT);

  const isSeller = computed(() => userMode.value === UserModeEnum.SELLER);

  const changeMode = () => {
    const mode = useCookie("user-mode");

    if (sellerStore.isSeller && userMode.value === UserModeEnum.DEFAULT) {
      mode.value = UserModeEnum.SELLER;
    } else {
      mode.value = UserModeEnum.DEFAULT;
    }

    userMode.value = mode.value;

    navigateTo("/");
  };

  const mountUserMode = () => {
    const mode = useCookie("user-mode");

    if (!mode.value || userStore.user?.company === null) {
      userMode.value = UserModeEnum.DEFAULT;
      mode.value = userMode.value;
    } else {
      userMode.value = mode.value;
    }
  };

  const getChangeButtonText = computed(() => {
    if (isSeller.value) {
      return "Режим покупця";
    }

    return "Режим продавця";
  });

  const getHomeActionsLink = computed(() => {
    if (isSeller.value) {
      return [
        {
          id: 0,
          link: "/my-shop/my-requests",
          name: "Вхідні заявки",
          icon: "mdi-clipboard-list-outline",
        },
        {
          id: 1,
          link: "/my-shop/filters",
          name: "Фільтр",
          icon: "mdi-filter-variant",
        },
        {
          id: 2,
          link: "/my-shop",
          name: "Профіль магазину",
          icon: "mdi-account-circle-outline",
        },
        {
          id: 3,
          link: "/wallet",
          name: "Гаманець",
          icon: "mdi-playlist-check",
        },
      ];
    }

    return [
      {
        id: 0,
        link: "/request",
        name: "Нова заявка",
        icon: "mdi-plus-circle-outline",
      },
      {
        id: 1,
        link: "/my-requests",
        name: "Мої заявки",
        icon: "mdi-clipboard-list-outline",
      },
      {
        id: 2,
        link: "/dashboard/my-cars",
        name: "Мій гараж",
        icon: "mdi-garage-open-variant",
      },
      {
        id: 3,
        link: "/",
        name: "Служба підтримки",
        icon: "mdi-face-agent",
      },
    ];
  });

  const getBurgerLinks = computed(() => {
    if (isSeller.value) {
      return [
        {
          id: 1,
          name: "Фільтр заявок",
          link: "/my-shop/filters",
        },
        {
          id: 2,
          name: "Вхідні заявки",
          link: "/my-shop/my-requests",
        },
        // {
        //   id: 3,
        //   name: "Знижки",
        //   link: "/",
        // },
        // {
        //   id: 4,
        //   name: "Статистика",
        //   link: "/",
        // },
        // {
        //   id: 5,
        //   name: "Преміум",
        //   link: "/",
        // },
        {
          id: 6,
          name: "Служба підтримки",
          link: "/support",
        },
        {
          id: 7,
          name: "Оплата і доставка",
          link: "/my-shop/settings",
        },
      ];
    }

    return [
      {
        id: 0,
        name: "Мої заявки",
        link: "/my-requests",
      },
      {
        id: 1,
        name: "Знижки на запчастини",
        link: "/my-shop/filters",
      },
      {
        id: 2,
        name: "Для продавців",
        link: "/partners",
      },
      {
        id: 3,
        name: "Служба підтримки",
        link: "/support",
      },
      {
        id: 4,
        name: "Преміум",
        link: "/",
      },
    ];
  });

  const getAccountLink = computed(() => {
    if (isSeller.value) {
      return {
        name: "Профіль магазину",
        link: "/my-shop",
      };
    }

    return {
      name: "Мій аккаунт",
      link: "/dashboard",
    };
  });

  const getBurgerAccount = computed(() => {
    if (isSeller.value) {
      return {
        avatar: "",
        name: userStore.user?.company?.name,
        link: "/my-shop",
        rating: "2.0",
        comments: 123,
      };
    }

    return {
      avatar: userStore.user?.avatar?.preview,
      name: userStore.user?.name,
      link: "/dashboard",
      rating: "5.0",
      comments: 234,
    };
  });

  return {
    userMode,
    changeMode,
    mountUserMode,
    getChangeButtonText,
    getHomeActionsLink,
    isSeller,
    getBurgerLinks,
    getAccountLink,
    getBurgerAccount,
  };
});
