import type { SellerInterface } from "./seller";

export enum RoleEnum {
    ADMIN = "admin",
    SELLER = "seller",
    BUYER = "customer",
}

export type UserRole = `${RoleEnum}`;

export interface UserInterface {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
  roles: UserRole[];
  phones: {
    code: string;
    create_at: string;
    id: number;
    number: string;
    updated_at: string;
    user_id: number;
  }[];
  avatar: {
    preview: string;
    url: string;
  };
  company: SellerInterface | null;
}

export interface UserResponseInterface {
  data: UserInterface;
}

export interface EditProfileInterface {
  name?: string;
  phone?: string;
  email?: string;
}


