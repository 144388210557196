import type { UserInterface, RoleEnum } from "~/models/user";
import { useError } from "#app";

export const roleMiddleware = (requiredRole: RoleEnum | RoleEnum[]) => {
  return defineNuxtRouteMiddleware((to, from) => {
    const error = useError();
    const user = useSanctumUser<UserInterface>();

    const roles = Array.isArray(requiredRole) ? requiredRole : [requiredRole];

    // Перевірка ролей
    if (!roles.some((role) => user.value.roles?.includes(role))) {
      error.value = {
        statusCode: 403,
        statusMessage: `Access Forbidden: You must have one of the following roles: ${roles.join(
          ", "
        )}`,
      };
      return navigateTo("/");
    }
  });
};
