import { defineStore } from "pinia";
import { useCustomersApi } from "@/composables/api/useCustomersApi";
import { useUserStore } from "@/stores/useUserStore";
import { RequestInterface } from "@/models/requests";

export const useCustomersStore = defineStore("customers-store", () => {
  const client = useSanctumClient();
  const customersApi = useCustomersApi();
  const userStore = useUserStore();

  const customersList = ref([]);
  const ordersList = ref<{
    data: RequestInterface[];
    links: { next: string; prev: string };
  }>({
    data: [],
    links: {
      next: "",
      prev: "",
    },
  });

  const order = ref<RequestInterface>(null);
  const offersList = ref({
    data: [],
    links: {
      next: "",
      prev: "",
    },
  });
  const offerInfo = ref(null);

  const getOffersList = async (id: string, quieries = { page: 1 }) => {
    try {
      const response = await client(
        `/api/customers/${userStore.user?.id}/orders/${id}/offers`,
        {
          params: {
            ...quieries,
          },
        }
      );

      offersList.value = {
        data: response.data,
        links: {
          next: response.links.next,
          prev: response.links.prev,
        },
      };
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  const getOffersById = async (orderId: string, offerId: string) => {
    try {
      const response = await client(
        `/api/customers/${userStore.user?.id}/orders/${orderId}/offers/${offerId}`
      );

      offerInfo.value = response.data;
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  const getCustomers = async () => {
    try {
      const { data: response } = await customersApi.getCustomers();
      customersList.value = response.data;
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const createOrder = async (data: unknown) => {
    try {
      await customersApi.createOrder(userStore.user?.id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getOrdersList = async (queries = { page: 1 }) => {
    try {
      const response = await client(
        `/api/customers/${userStore.user?.id}/orders`,
        {
          params: {
            ...queries,
          },
        }
      );
      ordersList.value = {
        data: response.data,
        links: {
          next: response.links.next,
          prev: response.links.prev,
        },
      };
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getOrder = async (orderId: number | string) => {
    try {
      const { data: response } = await customersApi.getOrder(
        userStore.user?.id,
        orderId
      );
      order.value = response.data;
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const editOrder = async (orderId: number, data: unknown) => {
    try {
      await customersApi.editOrder(userStore.user?.id, orderId, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const deleteOrder = async (orderId: number) => {
    try {
      await customersApi.deleteOrder(userStore.user?.id, orderId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const confirmOffer = async (
    orderId: number,
    offerId: number,
    data: unknown
  ) => {
    try {
      const response = await client(
        `/api/customers/${userStore.user?.id}/orders/${orderId}/offers/${offerId}/confirm`,
        {
          method: "POST",
          body: data,
        }
      );
    } catch (error) {
      throw error;
    }
  };

  const cancelOffer = async (orderId: number) => {
    try {
      await client(
        `/api/customers/${userStore.user?.id}/orders/${orderId}/cancel`,
        {
          method: "POST",
        }
      );
    } catch (error) {
      throw error;
    }
  };

  return {
    getCustomers,
    customersList,
    createOrder,
    getOrdersList,
    ordersList,
    getOrder,
    order,
    editOrder,
    deleteOrder,
    getOffersList,
    offersList,
    getOffersById,
    offerInfo,
    confirmOffer,
    cancelOffer,
  };
});
