<template>
  <v-dialog v-model="adminStore.isCarsModalOpen" width="auto">
    <v-card class="main-dialog" width="1000" height="830">
      <div class="main-dialog__content">
        <div class="main-dialog__header">
          <div v-if="getFormattedTitle" class="main-dialog__header-title">
            {{ getFormattedTitle }}
          </div>
          <v-icon
            @click="adminStore.handleCloseCarsModal"
            class="icon main-dialog__header-close"
            >mdi-close</v-icon
          >
        </div>

        <div>
          <div class="main-dialog__divider">
            {{ getFormattedLabel }}
          </div>

          <v-autocomplete
            label="Марка"
            v-if="adminStore.carsModalOptions.type === 'model'"
            :disabled="adminStore.carsModalOptions.action === 'edit'"
            variant="outlined"
            :density="'compact'"
            v-model="adminStore.carsModalOptions.parent_id"
            :items="carsStore.brandsList"
            item-title="name"
            item-value="id"
          />

          <v-text-field
            v-model="adminStore.carsModalOptions.title"
            hide-details
            :label="getFormattedLabel || 'Назва'"
            variant="outlined"
            :density="'compact'"
          ></v-text-field>
        </div>
      </div>
      <div class="main-dialog__footer">
        <v-btn color="black" @click="save">Зберегти</v-btn>
        <v-btn
          color="black"
          variant="outlined"
          @click="adminStore.handleCloseCarsModal"
          >Відмінити</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useAdminStore } from "~/stores/useAdminStore";

const adminStore = useAdminStore();
const carsStore = useCarsStore();

const getFormattedTitle = computed(() => {
  if (adminStore.carsModalOptions.action === "edit") {
    return adminStore.carsModalOptions.title;
  }

  if (adminStore.carsModalOptions.action === "create") {
    if (adminStore.carsModalOptions.type === "brand") {
      return "Додати марку";
    }

    if (adminStore.carsModalOptions.type === "model") {
      return "Додати модель";
    }
  }

  return null;
});

const getFormattedLabel = computed(() => {
  if (adminStore.carsModalOptions.type === "brand") {
    return "Назва марки";
  }

  if (adminStore.carsModalOptions.type === "model") {
    return "Назва моделі";
  }

  return null;
});

const save = async () => {
  if (adminStore.carsModalOptions.action === "edit") {
    if (adminStore.carsModalOptions.type === "brand") {
      await adminStore.updateBrandById(
        adminStore.carsModalOptions.id,
        adminStore.carsModalOptions.title
      );
    }

    if (adminStore.carsModalOptions.type === "model") {
      await adminStore.updateModelById(
        adminStore.carsModalOptions.id,
        adminStore.carsModalOptions.title
      );
    }
  } else {
    if (adminStore.carsModalOptions.type === "brand") {
      await adminStore.createBrand(adminStore.carsModalOptions.title);
    }

    if (adminStore.carsModalOptions.type === "model") {
      await adminStore.createModel(
        adminStore.carsModalOptions.parent_id,
        adminStore.carsModalOptions.title
      );
    }
  }
};
</script>

<style scoped lang="scss">
.main-dialog {
  &__content {
    padding: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 28px;
    position: relative;
  }

  &__header-title {
    color: var(--color-main-dark);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__header-close {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--color-main-dark);
    cursor: pointer;
    font-size: 27px !important;
  }

  &__divider {
    padding: 43px 0 32px;
    color: var(--color-primary-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.05px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 12;
    padding: 20px 40px;
    border-top: 1px solid #d9d9d9;
    margin-top: auto;
    gap: 12px;
  }
}
</style>
