<template>
  <div class="reviews">
    <div class="main-dialog__title">Відгуки {{ title }}</div>

    <div class="reviews__info">
      <v-rating
        :size="32"
        :model-value="1"
        :length="1"
        density="default"
        :active-color="'#FB8C00'"
      ></v-rating>

      <span class="ml-1">{{ Number(rating || "0").toFixed(1) }}</span>

      <span class="ml-4">{{ reviews.length }} Відгуків</span>
    </div>

    <div class="reviews__card-wrapper">
      <div
        v-for="item in reviews"
        :key="item.id"
        @click="item.expand = !item.expand"
        class="reviews__card"
      >
        <div v-if="item.expand" class="reviews__card-order">
          <div class="reviews__card-order-title">
            № Заявка {{ item?.order?.id }}
          </div>
          <div class="reviews__card-order-desc">
            {{ item.order?.part_code }}
          </div>
          <div class="reviews__card-order-info">
            <img v-lazy-load 
              data-src="https://imageio.forbes.com/specials-images/imageserve/5d35eacaf1176b0008974b54/2020-Chevrolet-Corvette-Stingray/0x0.jpg?format=jpg&crop=4560,2565,x790,y784,safe&width=960"
              alt=""
            />
            <div class="reviews__card-order-price">
              <span v-if="item.order?.confirmed_offer !== null"
                >Ціна: {{ item.order?.confirmed_offer?.price }} грн</span
              >
              <span class="reviews__card-order-status"
                >Заявка {{ formatRequestStatus(item.order.status) }}</span
              >
            </div>
          </div>
        </div>
        <AdminReviewCard
          class="reviews__card-review"
          :hideDetails="true"
          :rating="item?.rating"
          :date="item.created_at"
          :comment="item.comment"
          :name="item?.user?.name || 'Анонімний користувач'"
          :avatar="item?.user?.avatar"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AdminReviewCard from "../AdminReviewCard.vue";
import { RequestStatusEnum } from "@/models/requests";

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  rating: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: "",
  },
});

const reviews = ref([]);

const formatRequestStatus = (status: number) => {
  switch (status) {
    case RequestStatusEnum.ACTIVE:
      return "активна";
    case RequestStatusEnum.CONFIRMED:
      return "погоджена";
    case RequestStatusEnum.ARCHIVED:
      return "архівована";
    case RequestStatusEnum.CENCELED:
      return "скасована";
    case RequestStatusEnum.EXPIRED:
      return "протермінована";
    default:
      return "unknow";
  }
};

onMounted(() => {
  reviews.value = props.items.map((item) => {
    return {
      ...item,
      expand: false,
    };
  });
});
</script>

<style scoped lang="scss">
.reviews {
  padding: 0 5px 5px;

  &__info {
    display: flex;
    align-items: center;
    color: var(--color-primary-black);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-border-default);
    margin-bottom: 12px;
  }

  &__card {
    padding: 12px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 12px;
  }

  &__card-wrapper {
    overflow: auto;
    max-height: 400px;
    padding: 5px;
  }

  &__card-review {
    padding: 0;
    border: 0;
    box-shadow: none;
  }

  &__card-order {
    padding-bottom: 24px;
    border-bottom: 2px solid var(--color-border-default);
    margin-bottom: 24px;
  }

  &__card-order-title {
    color: var(--color-primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 12px;
  }

  &__card-order-desc {
    color: var(--color-primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
  }

  &__card-order-info {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 12px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
  }

  &__card-order-price {
    display: flex;
    flex-direction: column;
    gap: 12px;

    color: var(--color-primary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.014px;
  }

  &__card-order-status {
    color: var(--color-main-success);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
  }
}
</style>
