import { defineStore } from "pinia";

import { useCarsApi } from "@/composables/api/useCarsApi";

export const useCarsStore = defineStore("cars-store", () => {
  const carsApi = useCarsApi();
  const brandsList = ref([]);
  const modelsList = ref([]);
  const generationsList = ref([]);
  const enginesList = ref([]);
  const capacitiesList = ref([]);
  const transmissionsList = ref([]);

  const getCarBrands = async () => {
    try {
      const { data: response } = await carsApi.getCarBrands();
      brandsList.value = response.data || [];
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getCarModelsByBrand = async (brandId: number) => {
    try {
      const { data: response } = await carsApi.getCarModelsByBrand({ brandId });
      modelsList.value = response.data;
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getCarGenerationsByModel = async (brandId: number, modelId: number) => {
    try {
      const { data: response } = await carsApi.getCarGenerationsByModel({
        brandId,
        modelId,
      });
      generationsList.value = response.data;
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getCarEnginesByGeneration = async (
    brandId: number,
    modelId: number,
    generationId: number
  ) => {
    try {
      const { data: response } = await carsApi.getCarEnginesByGeneration({
        brandId,
        modelId,
        generationId,
      });
      enginesList.value = response.data;
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getCarCapacityByGeneration = async (
    brandId: number,
    modelId: number,
    generationId: number
  ) => {
    try {
      const { data: response } = await carsApi.getCarCapacityByGeneration({
        brandId,
        modelId,
        generationId,
      });
      capacitiesList.value = response.data;
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const getCarTransmissionsByGeneration = async (
    brandId: number,
    modelId: number,
    generationId: number
  ) => {
    try {
      const { data: response } = await carsApi.getCarTransmissionsByGeneration({
        brandId,
        modelId,
        generationId,
      });
      transmissionsList.value = response.data;
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    brandsList,
    modelsList,
    generationsList,
    enginesList,
    capacitiesList,
    transmissionsList,
    getCarBrands,
    getCarModelsByBrand,
    getCarGenerationsByModel,
    getCarEnginesByGeneration,
    getCarCapacityByGeneration,
    getCarTransmissionsByGeneration,
  };
});
