export type Nullable = null | undefined;

export enum NodeType {
    ROOT = 'root',
    BRAND = 'brand',
    MODEL = 'model',
    GENERATION = 'generation',
    PART = 'part',
    CHILD_PART = 'child_part',
}

export type Node = TreeNode | RootNode;

export type RootNode = RootNodeInterface;

export type TreeNode = TreeNodeInterface;

export interface RootNodeInterface {
    name: string;
    type: NodeType.ROOT;
    selected: boolean;
}

export interface TreeNodeInterface {
    id: number;
    name: string;
    type: NodeType;
    selected: boolean;
    link?: string | null;
}

export type PartTreeNode = PartInterface;

export type CarTreeNode = BrandInterface | ModelInterface | GenerationInterface;

export interface BrandInterface {
    id: number;
    name: string;
    models: ModelInterface[];
    created_at: string;
    updated_at: string;
}

export interface ModelInterface {
    id: number;
    name: string;
    generations: GenerationInterface[];
    updated_at: string;
    created_at: string;
}

export interface GenerationInterface {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

export interface PartInterface {
    id: number;
    name: string;
    children?: PartInterface[];
    created_at: string;
    updated_at: string;
}
