<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4128 16.8646L2 22L7.27892 20.6219C8.7335 21.4116 10.371 21.8278 12.0375 21.8284H12.0418C17.53 21.8284 21.9976 17.3821 22 11.918C22.0009 9.26974 20.9658 6.77983 19.086 4.90656C17.2056 3.03328 14.7055 2.00129 12.0418 2C6.55266 2 2.08579 6.44562 2.08356 11.91C2.083 13.6567 2.54143 15.3617 3.4128 16.8646ZM3.76493 11.9106C3.76661 7.36895 7.47945 3.67404 12.045 3.67404C14.2558 3.67496 16.3337 4.53276 17.8965 6.08965C19.4592 7.64653 20.3193 9.71625 20.3184 11.9172C20.3166 16.4592 16.6037 20.1545 12.0418 20.1545H12.0386C10.5531 20.1538 9.09645 19.7568 7.82596 19.0062L7.52346 18.8277L4.39083 19.6454L5.22718 16.6059L5.03022 16.2944C4.20176 14.9827 3.76426 13.4669 3.76493 11.9106Z"
      fill="#29A71A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.55302 7.76688C9.36652 7.35449 9.17031 7.34614 8.99313 7.3389C8.84802 7.33277 8.68203 7.33314 8.51623 7.33314C8.35024 7.33314 8.08073 7.39515 7.85282 7.64303C7.62472 7.89091 6.98183 8.49008 6.98183 9.70885C6.98183 10.9276 7.87352 12.1052 7.99773 12.2706C8.12214 12.4359 9.71901 15.0167 12.2482 16.0096C14.35 16.8347 14.7777 16.6706 15.2341 16.6293C15.6903 16.5881 16.7062 16.0302 16.9134 15.4518C17.1208 14.8734 17.1208 14.3778 17.0587 14.2742C16.9964 14.1708 16.8306 14.109 16.5816 13.9851C16.3328 13.8613 15.1095 13.2619 14.8814 13.1793C14.6533 13.0967 14.4875 13.0555 14.3215 13.3033C14.1557 13.5512 13.6791 14.109 13.5338 14.2742C13.3887 14.4397 13.2436 14.4603 12.9947 14.3364C12.7459 14.2122 11.9444 13.951 10.9938 13.1071C10.2541 12.4505 9.75483 11.6397 9.60954 11.3918C9.46443 11.1439 9.59406 11.0099 9.71883 10.8864C9.83055 10.7754 9.96763 10.5971 10.0922 10.4525C10.2162 10.3078 10.2578 10.2046 10.3406 10.0395C10.4236 9.8741 10.3822 9.72946 10.3199 9.60562C10.2578 9.48177 9.77422 8.25669 9.55302 7.76688Z"
      fill="#29A71A"
    />
  </svg>
</template>
