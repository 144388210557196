import { defineStore } from "pinia";
import { type EditProfileInterface, type UserInterface } from "~/models/user";
import type { CarInterface } from "~/models/cars";
import { useUserApi } from "@/composables/api/useUserApi";

export const useUserStore = defineStore("user-store", () => {
  const userApi = useUserApi();
  const loading = ref(false);

  const user = useSanctumUser<UserInterface>();
  const isAuth = computed(() => user.value !== null);
  const myGarage = ref<CarInterface[]>([]);

  const refreshUser = async () => {
    const { refreshIdentity } = useSanctumAuth();
    refreshIdentity();
  };

  const egitProfile = async (data: EditProfileInterface, id: number) => {
    try {
      loading.value = true;
      const { data: response } = await userApi.egitProfile(data, id);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const uploadAvatar = async (file: any) => {
    try {
      loading.value = true;
      await userApi.uploadAvatar(file, user.value?.id);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const changePasssword = async (data: {
    old_password: string;
    password: string;
    password_confirmation: string;
  }) => {
    try {
      loading.value = true;
      await userApi.changePasssword(data, user.value?.id);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const changeEmail = async (data: { email: string; password: string }) => {
    try {
      loading.value = true;
      await userApi.changeEmail(data, user.value?.id);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const addCarInGarage = async (data: any) => {
    try {
      loading.value = true;
      await userApi.addCarInGarage(data, user.value?.id);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getCarsInGarage = async () => {
    try {
      loading.value = true;
      const response = await userApi.getCarsInGarage(user.value?.id);
      myGarage.value = response.data.data || [];
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getCarInGarage = async (carId: number) => {
    try {
      loading.value = true;
      return await userApi.getCarInGarage(user.value?.id, carId);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const editCarById = async (carId: number, data: any) => {
    try {
      loading.value = true;
      return await userApi.editCarById(user.value?.id, carId, data);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const deleteCarInGarage = async (carId: number) => {
    try {
      loading.value = true;
      await userApi.deleteCarInGarage(user.value?.id, carId);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    egitProfile,
    uploadAvatar,
    user,
    isAuth,
    changePasssword,
    changeEmail,
    addCarInGarage,
    getCarsInGarage,
    myGarage,
    getCarInGarage,
    deleteCarInGarage,
    editCarById,
    refreshUser,
  };
});
