export const useCustomersApi = () => {
  const { $axios } = useNuxtApp();

  const getCustomers = async () => {
    return await $axios.get(`/api/customers`);
  };

  const createOrder = (customer: number, data: unknown) => {
    return $axios.post(`/api/customers/${customer}/orders`, data);
  };

  const getOrdersList = async (customer: number) => {
    return await $axios.get(`/api/customers/${customer}/orders`);
  };

  const getOrder = async (customer: number, order: number) => {
    return await $axios.get(`/api/customers/${customer}/orders/${order}`);
  };

  const editOrder = async (customer: number, order: number, data: unknown) => {
    return await $axios.post(`/api/customers/${customer}/orders/${order}`, {
      ...data,
      _method: "PUT",
    });
  };

  const deleteOrder = async (customer: number, order: number) => {
    return await $axios.delete(`/api/customers/${customer}/orders/${order}`);
  };

  return {
    getCustomers,
    createOrder,
    getOrdersList,
    getOrder,
    editOrder,
    deleteOrder,
  };
};
