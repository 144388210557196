import { defineStore } from "pinia";
import { useHttpErrorHandler } from "~/composables/useHttpErrorHandler";

export const useAdminStore = defineStore("admin-store", () => {
  const client = useSanctumClient();
  const { errorHandle } = useHttpErrorHandler();
  const isSellerModalOpen = ref(false);
  const isCarsModalOpen = ref(false);
  const isPartsModalOpen = ref(false);
  const isRequestModalOpen = ref(false);
  const currentRequestId = ref(null);
  const currentSellerId = ref(null);
  const currentSellerInfo = ref({});
  const currentCustomerId = ref(null);
  const currentCustomerInfo = ref({});
  const isCustomerModalOpen = ref(false);
  const partsModalOptions = ref({
    title: "",
    type: "",
    id: "",
    action: "",
    parent_id: "",
  });
  const carsModalOptions = ref({
    title: "",
    type: "",
    id: "",
    action: "",
    parent_id: "",
  });
  const userList = ref({
    list: [],
    links: {
      prev: "",
      next: "",
    },
  });
  const companiesList = ref({
    list: [],
    links: {
      prev: "",
      next: "",
    },
  });
  const brandsList = ref({
    list: [],
    links: {
      prev: "",
      next: "",
    },
  });
  const modelList = ref({
    list: [],
    links: {
      prev: "",
      next: "",
    },
  });

  const partCategoiresList = ref({
    list: [],
    links: {
      prev: "",
      next: "",
    },
  });

  const requestsList = ref({
    list: [],
    links: {
      prev: "",
      next: "",
    },
  });

  const openSellerDialog = (id: number) => {
    currentSellerId.value = id;
    isSellerModalOpen.value = true;
  };

  const closeSellerDialog = () => {
    currentSellerId.value = null;
    isSellerModalOpen.value = false;
  };

  const getSellerInfoById = async (id) => {
    try {
      const response = await client(`api/admin/companies/${id} `);
      currentSellerInfo.value = response.data;
    } catch (error) {
      errorHandle(error);
    }
  };

  const getUserList = async (params = {}) => {
    try {
      const response = await client("/api/admin/users", {
        params,
      });
      userList.value = {
        list: response.data,
        links: {
          prev: response.links.prev,
          next: response.links.next,
        },
      };
    } catch (error) {
      errorHandle(error);
    }
  };

  const getCompaniesList = async (params = {}) => {
    try {
      const response = await client("api/admin/companies", {
        params,
      });
      companiesList.value = {
        list: response.data,
        links: {
          prev: response.links.prev,
          next: response.links.next,
        },
      };
    } catch (error) {
      errorHandle(error);
    }
  };

  const getBrandsList = async (params = {}) => {
    try {
      const response = await client("api/admin/brands", {
        params,
      });
      brandsList.value = {
        list: response.data,
        links: {
          prev: response.links.prev,
          next: response.links.next,
        },
      };
    } catch (error) {
      errorHandle(error);
    }
  };

  const deleteBrandById = async (id) => {
    try {
      await client(`api/admin/brands/${id}`, {
        method: "DELETE",
      });
      getBrandsList();
    } catch (error) {
      errorHandle(error);
    }
  };

  const updateBrandById = async (id, name) => {
    try {
      await client(`api/admin/brands/${id}`, {
        method: "PUT",
        body: {
          name,
        },
      });
      getBrandsList();
      isCarsModalOpen.value = false;
    } catch (error) {
      errorHandle(error);
    }
  };

  const createBrand = async (name) => {
    try {
      await client("api/admin/brands", {
        method: "POST",
        body: {
          name,
        },
      });
      getBrandsList();
      isCarsModalOpen.value = false;
    } catch (error) {
      errorHandle(error);
    }
  };

  const getModelList = async (brand_id = "all-models", params = {}) => {
    try {
      const data = {
        ...params,
      };

      if (brand_id && brand_id !== "all-models") {
        data.brand_id = brand_id;
      }

      const response = await client(`api/admin/models`, {
        params: data,
      });
      modelList.value = {
        list: response.data,
        links: {
          prev: response.links.prev,
          next: response.links.next,
        },
      };
    } catch (error) {
      errorHandle(error);
    }
  };

  const createModel = async (car_brand_id: number, name: string) => {
    try {
      await client(`api/admin/models`, {
        method: "POST",
        body: {
          name,
          car_brand_id,
        },
      });
      getModelList();
      isCarsModalOpen.value = false;
    } catch (error) {
      errorHandle(error);
    }
  };

  const updateModelById = async (id: number, name: string) => {
    try {
      await client(`api/admin/models/${id}`, {
        method: "PUT",
        body: {
          name,
        },
      });
      getModelList();
      isCarsModalOpen.value = false;
    } catch (error) {
      errorHandle(error);
    }
  };

  const deleteModelById = async (id: number) => {
    try {
      await client(`api/admin/models/${id}`, {
        method: "DELETE",
      });
      getModelList();
    } catch (error) {
      errorHandle(error);
    }
  };

  const handleOpenCarsModal = (options) => {
    carsModalOptions.value = options;
    isCarsModalOpen.value = true;
  };

  const handleCloseCarsModal = () => {
    isCarsModalOpen.value = false;
  };

  const handleOpenPartsModal = (options) => {
    partsModalOptions.value = options;
    isPartsModalOpen.value = true;
  };

  const handleClosePartsModal = () => {
    isPartsModalOpen.value = false;
  };

  const getPartsCategoriesList = async (
    params = { order_by: "id", order_direction: "asc" }
  ) => {
    try {
      const response = await client("api/admin/part-categories", {
        params,
      });
      partCategoiresList.value = {
        list: response.data,
        links: {
          prev: response.links.prev,
          next: response.links.next,
        },
      };
    } catch (error) {
      errorHandle(error);
    }
  };

  const createPartCategory = async (name) => {
    try {
      await client("api/admin/part-categories", {
        method: "POST",
        body: {
          parent_id: partsModalOptions.value.parent_id,
          name,
        },
      });
      getPartsCategoriesList();
      isPartsModalOpen.value = false;
    } catch (error) {
      errorHandle(error);
    }
  };

  const updatePartCategoryById = async (id, name) => {
    try {
      await client(`api/admin/part-categories/${id}`, {
        method: "PUT",
        body: {
          name,
        },
      });
      getPartsCategoriesList();
      isPartsModalOpen.value = false;
    } catch (error) {
      errorHandle(error);
    }
  };

  const deletePartsCategoryById = async (id) => {
    try {
      await client(`api/admin/part-categories/${id}`, {
        method: "DELETE",
      });
      getPartsCategoriesList();
    } catch (error) {
      errorHandle(error);
    }
  };

  const getRequestList = async (params = {}) => {
    try {
      const response = await client("api/admin/orders", {
        params,
      });
      requestsList.value = {
        list: response.data,
        links: {
          prev: response.links.prev,
          next: response.links.next,
        },
      };
    } catch (error) {
      errorHandle(error);
    }
  };

  const openRequestModal = (id: number) => {
    isRequestModalOpen.value = true;
    currentRequestId.value = id;
  };

  const handleCloseRequestModal = () => {
    isRequestModalOpen.value = false;
    currentRequestId.value = null;
  };

  const handleOpenCustomerModal = (id: number) => {
    isCustomerModalOpen.value = true;
    currentCustomerId.value = id;
  };

  const handleCloseCustomerModal = () => {
    isCustomerModalOpen.value = false;
    currentCustomerId.value = null;
  };

  const getCustomerById = async (id: number) => {
    try {
      const response = await client(`api/admin/users/${id}`);
      currentCustomerInfo.value = response.data;
    } catch (error) {
      errorHandle(error);
    }
  };

  return {
    getCustomerById,
    handleOpenCustomerModal,
    handleCloseCustomerModal,
    isCustomerModalOpen,
    currentCustomerId,
    currentCustomerInfo,
    getSellerInfoById,
    currentSellerInfo,
    currentSellerId,
    handleCloseRequestModal,
    currentRequestId,
    openRequestModal,
    isRequestModalOpen,
    requestsList,
    getRequestList,
    createPartCategory,
    updatePartCategoryById,
    deletePartsCategoryById,
    isPartsModalOpen,
    handleOpenPartsModal,
    handleClosePartsModal,
    partsModalOptions,
    getPartsCategoriesList,
    partCategoiresList,
    updateBrandById,
    deleteBrandById,
    handleCloseCarsModal,
    handleOpenCarsModal,
    carsModalOptions,
    isCarsModalOpen,
    modelList,
    getModelList,
    getBrandsList,
    brandsList,
    getCompaniesList,
    companiesList,
    getUserList,
    userList,
    isSellerModalOpen,
    openSellerDialog,
    closeSellerDialog,
    createBrand,
    createModel,
    updateModelById,
    deleteModelById,
  };
});
