import type {
  CarBrandInterface,
  CarModelInterface,
  CarGenerationInterface,
} from "./cars";
import type { PartCategoryInterface } from "./parts";
import type { SellerInterface } from "./seller";
import type { UserInterface } from "./user";

export enum RequestStatusEnum {
  ACTIVE = 0,
  CONFIRMED = 1,
  ARCHIVED = 2,
  CENCELED = 3,
  EXPIRED = 4,
}

export interface RequestInterface {
  id: number;
  customer: UserInterface;
  seller: SellerInterface | null;
  offers: [];
  car_brand: CarBrandInterface;
  car_model: CarModelInterface;
  part_category: PartCategoryInterface;
  status: RequestStatusEnum;
  part_code: string;
  part_description: string;
  comment: string;
  recommended_price: number;
  part_type: string;
  part_condition: string;
  created_at: string;
  updated_at: string;
}
