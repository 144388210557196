<template>
  <component
    :is="isLink ? 'nuxt-link' : 'div'"
    :to="`/my-requests/${item.id}`"
    class="request-card"
  >
    <div class="request-card__header">
      <div class="request-card__header-info">
        <div class="request-card__header-title">
          № Заявка {{ item.id }}
          <span v-if="item.stauts === 0" class="request-card__header-status"
            >Відредаговано</span
          >
        </div>
        <div class="request-card__header-model">
          {{ item.car_brand.name }} {{ item.car_model.name }}
          {{ item.year }}
        </div>
      </div>
      <div v-if="isEdit" class="request-card__header-action">
        <nuxt-link :to="`/my-requests/${item.id}/edit`">
          <v-icon class="icon">mdi-pencil-outline</v-icon>
        </nuxt-link>
      </div>
    </div>
    <div class="request-card__text">
      {{ item.part_category.name }}
    </div>
    <div class="request-card__deliver"></div>
    <div class="request-card__info">
      <div class="request-card__info-item">
        {{ dayjs(item.updated_at).format("YYYY.MM.DD") }}
      </div>
      <div class="request-card__info-item">
        Пропозиціїй: {{ item?.offers?.length }}
      </div>
      <div class="request-card__info-item">
        Статус:
        <span class="request-card__info-status">{{
          getItemStatus(item.status)
        }}</span>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { useDayjs } from "#dayjs";

const props = defineProps({
  item: Object,
  isLink: Boolean,
  isEdit: Boolean,
});

const dayjs = useDayjs();

const getItemStatus = (status: number) => {
  switch (status) {
    case 0:
      return "В опрацюванні";
    default:
      return "Невідомий";
  }
};
</script>

<style scoped lang="scss">
.request-card {
  display: block;
  background: var(--color-main-white);
  padding: 12px;
  border-radius: 8px;
  margin: 8px 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__header-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__header-title {
    color: var(--color-primary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;
  }

  &__header-model {
    color: var(--color-primary-black);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;
  }

  &__header-status {
    color: var(--color-main-warning);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;
    margin-left: 12px;
  }

  &__text {
    color: var(--color-main-dark);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 12px;
  }

  &__deliver {
    width: 100%;
    height: 1px;
    background: var(--color-main-success);
    margin-bottom: 12px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__info-item {
    color: var(--color-secondary-black);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;
  }
}
</style>
