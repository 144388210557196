<template>
  <div>
    <div class="tabs">
      <div
        v-for="item in tabStatus"
        @click="status = item.value"
        :key="item.value"
        class="tabs-item"
        :class="{ 'tabs-item--active': status === item.value }"
      >
        {{ item.title }}
      </div>
    </div>

    <RequestCard
      class="mb-6"
      v-for="item in items"
      :key="item.id"
      :item="item"
      :isEdit="false"
      :isLink="false"
      @click="handleClick(item.id)"
    />
  </div>
</template>

<script setup lang="ts">
import { RequestStatusEnum } from "@/models/requests";
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["changeStatus"]);

const adminStore = useAdminStore();

const handleClick = (id) => {
  adminStore.handleCloseCustomerModal();
  adminStore.closeSellerDialog();
  navigateTo("/admin/requests");
};

const status = ref(0);

watch(
  () => status.value,
  (value) => {
    emit("changeStatus", value);
  }
);

const tabStatus = computed(() => [
  {
    title: "Активні",
    value: RequestStatusEnum.ACTIVE,
  },
  {
    title: "Погоджені",
    value: RequestStatusEnum.CONFIRMED,
  },
  {
    title: "Архів",
    value: RequestStatusEnum.ARCHIVED,
  },
  {
    title: "Скасовані",
    value: RequestStatusEnum.CENCELED,
  },
  {
    title: "Протерміновані",
    value: RequestStatusEnum.EXPIRED,
  },
]);
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  gap: 16px;

  &-item {
    opacity: 0.5;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }

  &-item--active {
    opacity: 1;
    color: #1976d2;
    border-bottom: 2px solid #1976d2;
  }
}
</style>
