<template>
  <div class="car" :class="{ 'car-card': card }">
    <v-icon class="car__icon">mdi-car-back</v-icon>
    <div class="car__info">
      <div class="car__info-title">{{ brand }} {{ model }} {{ year }}</div>
      <div v-if="getInformation" class="car__info-description mt-1">
        {{ getInformation }}
      </div>
    </div>
    <div class="car__action">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  card: {
    type: Boolean,
    default: false,
  },
  brand: {
    type: String,
    default: "",
  },
  model: {
    type: String,
    default: "",
  },
  year: {
    type: Number,
    default: "",
  },
  engine: {
    type: String,
    default: "",
  },
  transmission: {
    type: String,
    default: "",
  },
  capacity: {
    type: String,
    default: "",
  },
});

const { getCarTransmissionType, getCarEngineType } = useCarsSettings();

const getInformation = computed(() => {
  const capacity = props.capacity ? `${props.capacity} ` : "";
  const engine = props.engine
    ? `${
        getCarEngineType.value.find((el) => el.value === props.engine)?.label
      }, `
    : "";
  const transmission = props.transmission
    ? `${
        getCarTransmissionType.value.find(
          (el) => el.value === props.transmission
        )?.label
      }`
    : "";

  return `${capacity}${engine}${transmission}`;
});
</script>

<style scoped lang="scss">
.car {
  display: flex;
  align-items: center;
  gap: 12px;

  &-card {
    border-radius: 4px;
    background: var(--color-main-white);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 12px;
  }

  &__action {
    margin-left: auto;
  }

  &__icon {
    font-size: 60px;
  }

  &__info-title {
    color: var(--color-primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  &__info-description {
    color: var(--color-secondary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}
</style>
