<template>
  <div class="home">
    <div class="main-dialog__title">
      Профіль магазину {{ adminStore.currentSellerInfo.name }}
    </div>

    <div class="home__info">
      <AvatarCustomer :src="adminStore.currentSellerInfo.avatar" />
      <div class="home__info-shop">
        <div class="home__subtitle">Про магазин</div>
        <SlicedDescription :text="adminStore.currentSellerInfo.description" />
      </div>
    </div>

    <div class="home__shop-info">
      <div class="home__subtitle">Загальна інформація</div>

      <div
        v-if="adminStore.currentSellerInfo.address"
        class="home__shop-info__item"
      >
        <v-icon class="icon">mdi-map-marker-outline</v-icon>
        <div class="shop-info__item-text">
          {{ adminStore.currentSellerInfo.address }}
        </div>
      </div>

      <div
        v-if="adminStore.currentSellerInfo?.phones?.length"
        class="home__shop-info__item"
      >
        <v-icon class="icon">mdi-cellphone</v-icon>
        <div class="home__shop-info__item-text">
          <span
            v-for="item in adminStore.currentSellerInfo.phones"
            :key="item.id"
          >
            {{ item.code }}{{ item.number }}
            <IconTelegram v-if="item.telegram" />
            <IconViber v-if="item.viber" />
            <IconWhatsApp v-if="item.whatsapp" />
          </span>
        </div>
      </div>

      <div
        v-if="adminStore.currentSellerInfo.website"
        class="home__shop-info__item"
      >
        <v-icon class="icon">mdi-web</v-icon>

        <a :href="adminStore.currentSellerInfo.website" target="blank">{{
          adminStore.currentSellerInfo.website
        }}</a>
      </div>

      <div class="home__shop-info__item">
        <v-icon class="icon">mdi-email-outline</v-icon>
        <div class="home__shop-info__item-text">
          {{ adminStore.currentSellerInfo.email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AvatarCustomer from "~/components/AvatarCustomer.vue";
import SlicedDescription from "@/components/SlicedDescription.vue";
import IconTelegram from "@/components/Icons/IconTelegram.vue";
import IconViber from "@/components/Icons/IconViber.vue";
import IconWhatsApp from "@/components/Icons/IconWhatsApp.vue";

const adminStore = useAdminStore();
</script>

<style scoped lang="scss">
.home {
  &__subtitle {
    color: var(--color-primary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;
    margin-bottom: 16px;
  }

  &__info {
    display: flex;
    gap: 24px;
    margin-bottom: 50px;
  }

  &__shop-info__item {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }

  &__shop-info__item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    span {
      display: flex;
      gap: 12px;
    }
  }
}
</style>
