<template>
  <div class="request-form">
    <div>
      <v-autocomplete
        v-model="currentCar.car_brand_id"
        :disabled="loading"
        label="Марка"
        :items="carsList.brands"
        variant="outlined"
        item-title="name"
        item-value="id"
        @update:modelValue="getCarModelsByBrand"
        :error-messages="errors.car_brand_id"
        class="main-field mb-2"
      />

      <v-autocomplete
        v-model="currentCar.car_model_id"
        :disabled="loading || currentCar.car_brand_id === null"
        label="Модель"
        :items="carsList.models"
        variant="outlined"
        item-title="name"
        item-value="id"
        :error-messages="errors.car_model_id"
        class="main-field mb-2"
      />

      <v-text-field
        v-model="currentCar.year"
        :disabled="loading"
        label="Рік випуску"
        variant="outlined"
        :error-messages="errors.year"
        class="main-field mb-2"
      />

      <v-text-field
        v-if="fullForm"
        v-model="currentCar.engine_capacity"
        :disabled="loading"
        label="Об'єм двигуна"
        variant="outlined"
        :error-messages="errors.engine_capacity"
        @update:modelValue="errors.engine_capacity = ''"
        class="main-field mb-2"
      />

      <v-select
        v-if="fullForm"
        v-model="currentCar.engine_type"
        :disabled="loading"
        label="Тип двигуна"
        :items="getCarEngineType"
        variant="outlined"
        item-title="label"
        item-value="value"
        :error-messages="errors.engine_type"
        @update:modelValue="errors.engine_type = ''"
        class="main-field mb-2"
      ></v-select>

      <v-select
        v-if="fullForm"
        v-model="currentCar.transmission"
        :disabled="loading"
        label="Коробка передач"
        :items="getCarTransmissionType"
        variant="outlined"
        item-title="label"
        item-value="value"
        :error-messages="errors.transmission"
        @update:modelValue="errors.transmission = ''"
        class="main-field mb-2"
      ></v-select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type CurrentCarInterface } from "@/models/cars";
import type { VAutocomplete } from "vuetify/components";

const carsStore = useCarsStore();

const props = defineProps({
  currentCarIds: {
    type: Object as PropType<CurrentCarInterface>,
    default: () => ({}),
  },
  modelValue: {
    type: Object as PropType<CurrentCarInterface>,
    default: () => ({}),
  },
  errorsValue: {
    type: Object as PropType<CurrentCarInterface>,
    default: () => ({}),
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  fullForm: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue", "update:errorsValue"]);

const { getCarTransmissionType, getCarEngineType } = useCarsSettings();

const currentCar = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const errors = computed({
  get: () => props.errorsValue,
  set: (value) => emit("update:errorsValue", value),
});

const carsList = ref({
  brands: [],
  models: [],
});

const loading = ref(true);

const getCarBrands = async () => {
  try {
    loading.value = true;
    const response = await carsStore.getCarBrands();
    carsList.value.brands = response.data;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getCarModelsByBrand = async () => {
  try {
    errors.value.car_brand_id = null;

    if (currentCar.value.car_model_id) {
      currentCar.value.car_model_id = null;
    }

    loading.value = true;
    if (!currentCar.value.car_brand_id) return;

    const response = await carsStore.getCarModelsByBrand(
      currentCar.value.car_brand_id
    );
    carsList.value.models = response.data;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await getCarBrands();

  if (props.isEdit) {
    currentCar.value.car_brand_id = props.currentCarIds.car_brand_id;

    await getCarModelsByBrand();

    currentCar.value.car_brand_id = props.currentCarIds.car_brand_id;
    currentCar.value.car_model_id = props.currentCarIds.car_model_id;
    currentCar.value.year = props.currentCarIds.year;
    currentCar.value.engine_capacity = props.currentCarIds.engine_capacity;
    currentCar.value.engine_type = props.currentCarIds.engine_type;
    currentCar.value.transmission = props.currentCarIds.transmission;
    currentCar.value.vin = props.currentCarIds.vin;
  }
});
</script>
