<template>
  <v-dialog v-model="adminStore.isPartsModalOpen" width="auto">
    <v-card class="main-dialog" width="1000" height="830">
      <div class="main-dialog__content">
        <div class="main-dialog__header">
          <div v-if="getFormattedTitle" class="main-dialog__header-title">
            {{ getFormattedTitle }}
          </div>
          <v-icon
            @click="adminStore.handleClosePartsModal"
            class="icon main-dialog__header-close"
            >mdi-close</v-icon
          >
        </div>

        <div>
          <div class="main-dialog__divider">
            {{ getFormattedLabel }}
          </div>

          <v-autocomplete
            label="Група запчастин"
            :disabled="adminStore.partsModalOptions.action === 'edit'"
            variant="outlined"
            :density="'compact'"
            v-model="adminStore.partsModalOptions.parent_id"
            :items="getPartsList"
            item-title="name"
            item-value="id"
            @update:search="fetchPartsList"
          />

          <v-text-field
            v-model="adminStore.partsModalOptions.title"
            hide-details
            :label="getFormattedLabel || 'Назва'"
            variant="outlined"
            :density="'compact'"
          ></v-text-field>
        </div>
      </div>
      <div class="main-dialog__footer">
        <v-btn color="black" @click="save">Зберегти</v-btn>
        <v-btn
          color="black"
          variant="outlined"
          @click="adminStore.handleClosePartsModal"
          >Відмінити</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useAdminStore } from "~/stores/useAdminStore";

const adminStore = useAdminStore();
const client = useSanctumClient();

const getFormattedTitle = computed(() => {
  if (adminStore.partsModalOptions.action === "edit") {
    return adminStore.partsModalOptions.title;
  }

  if (adminStore.partsModalOptions.action === "create") {
    if (adminStore.partsModalOptions.type === "group") {
      return "Додати групу";
    }

    if (adminStore.partsModalOptions.type === "child") {
      return "Додати запчастину";
    }
  }

  return null;
});

const getFormattedLabel = computed(() => {
  if (adminStore.partsModalOptions.type === "group") {
    return "Назва групи";
  }

  if (adminStore.partsModalOptions.type === "child") {
    return "Назва запчастини";
  }

  return null;
});

const partsList = ref([]);

const fetchPartsList = async (search: string) => {
  try {
    if (search.length < 3 || search === "Корінь") {
      return;
    }

    const params = {
      search,
    };

    if (search === "") {
      delete params.search;
    }
    const response = await client("api/admin/part-categories", {
      params,
    });

    partsList.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

onMounted(async () => {
  const response = await client("api/admin/part-categories");

  partsList.value = response.data;
});

const getPartsList = computed(() => {
  const parts = partsList.value.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  parts.unshift({
    id: null,
    name: "Корінь",
  });

  return parts;
});

const save = async () => {
  if (adminStore.partsModalOptions.action === "edit") {
    await adminStore.updatePartCategoryById(
      adminStore.partsModalOptions.id,
      adminStore.partsModalOptions.title
    );
  } else {
    await adminStore.createPartCategory(adminStore.partsModalOptions.title);
  }
};
</script>

<style scoped lang="scss">
.main-dialog {
  &__content {
    padding: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 28px;
    position: relative;
  }

  &__header-title {
    color: var(--color-main-dark);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__header-close {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--color-main-dark);
    cursor: pointer;
    font-size: 27px !important;
  }

  &__divider {
    padding: 43px 0 32px;
    color: var(--color-primary-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.05px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 12;
    padding: 20px 40px;
    border-top: 1px solid #d9d9d9;
    margin-top: auto;
    gap: 12px;
  }
}
</style>
