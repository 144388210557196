<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47717 22 2 17.5228 2 12C2 6.47717 6.47717 2 12 2C17.5228 2 22 6.47717 22 12ZM8.69646 11.084C7.69471 11.5213 6.66546 11.9707 5.72427 12.4892C5.23282 12.849 5.88599 13.1035 6.49879 13.3423C6.59621 13.3802 6.69258 13.4178 6.78321 13.4553C6.85862 13.4785 6.93529 13.5029 7.013 13.5277C7.69454 13.7448 8.45446 13.9869 9.11613 13.6227C10.203 12.9983 11.2288 12.2765 12.2537 11.5552C12.5895 11.3189 12.9252 11.0826 13.263 10.8499C13.2788 10.8398 13.2966 10.8282 13.3162 10.8155C13.6039 10.629 14.251 10.2095 14.0116 10.7875C13.4456 11.4065 12.8393 11.9545 12.2298 12.5054C11.819 12.8767 11.4066 13.2494 11.0041 13.646C10.6536 13.9309 10.2895 14.5037 10.6821 14.9025C11.5862 15.5355 12.5045 16.153 13.4222 16.7703C13.7208 16.9711 14.0194 17.172 14.3174 17.3733C14.8225 17.7765 15.6119 17.4503 15.7229 16.8202C15.7723 16.5303 15.8219 16.2405 15.8715 15.9506C16.1455 14.3487 16.4195 12.7461 16.6617 11.139C16.6946 10.8868 16.7319 10.6347 16.7692 10.3825C16.8597 9.77125 16.9503 9.15921 16.9785 8.54462C16.9056 7.93129 16.162 8.06617 15.7481 8.20408C13.621 9.0135 11.515 9.88292 9.41746 10.7677C9.17983 10.8729 8.93896 10.9781 8.69646 11.084Z"
      fill="#039BE5"
    />
  </svg>
</template>
