import { useError } from "#app";

export const useHttpErrorHandler = () => {
  const errors = ref({});

  const error = useError();

  const errorHandle = (err) => {
    if (err.response) {
      const { status, statusText } = err.response;

      if (status === 422) {
        errors.value = err.response.data.errors;
        return;
      }

      error.value = {
        statusCode: status,
        statusMessage: statusText,
      };
    }
  };

  return {
    errors,
    errorHandle,
  };
};
